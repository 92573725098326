import _createForOfIteratorHelper from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
export default {
  data: function data() {
    return {
      editableTabsValue: '/',
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      visible: false
    };
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes: function routes() {
      return this.$store.state.permission.routes;
    },
    theme: function theme() {
      return this.$store.state.settings.theme;
    }
  },
  watch: {
    $route: function $route() {
      this.addTags();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.initTags();
    this.addTags();
    this.isActive();
    this.beforeUnload();
  },
  methods: {
    // 刷新前缓存tab
    beforeUnload: function beforeUnload() {
      var _this = this;
      // 监听页面刷新
      window.addEventListener('beforeunload', function () {
        var tabViews = _this.visitedViews.map(function (item) {
          return {
            fullPath: item.fullPath,
            hash: item.hash,
            meta: _objectSpread({}, item.meta),
            name: item.name,
            params: _objectSpread({}, item.params),
            path: item.path,
            query: _objectSpread({}, item.query),
            title: item.title
          };
        });
        sessionStorage.setItem('tabViews', JSON.stringify(tabViews));
      });
      // 页面初始化加载判断缓存中是否有数据
      var oldViews = JSON.parse(sessionStorage.getItem('tabViews')) || [];
      if (oldViews.length > 0) {
        this.$store.state.tagsView.visitedViews = oldViews;
      }
    },
    handleTagsOver: function handleTagsOver(index) {
      var tags = document.querySelectorAll('.tags-item');
      var item = tags[index - 1];
      item.style.cssText = "color:".concat(this.$store.state.settings.theme, ";background:").concat(this.$store.state.settings.theme.colorRgb());
    },
    handleTagsLeave: function handleTagsLeave(index) {
      var tags = document.querySelectorAll('.tags-item');
      var item = tags[index - 1];
      item.style.cssText = "color:#606266";
    },
    isActive: function isActive() {
      var _this2 = this;
      var index = this.visitedViews.findIndex(function (item) {
        return item.fullPath === _this2.$route.fullPath;
      });
      var pathIndex = index > -1 ? index : 0;
      this.editableTabsValue = this.visitedViews[pathIndex].fullPath;
    },
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags: function filterAffixTags(routes) {
      var _this3 = this;
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta)
          });
        }
        if (route.children) {
          var tempTags = _this3.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);
      var _iterator = _createForOfIteratorHelper(affixTags),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var tag = _step.value;
          // Must have tag name
          if (tag.name) {
            this.$store.dispatch('tagsView/addVisitedView', tag);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    addTags: function addTags() {
      var name = this.$route.name;
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route);
        this.isActive();
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this4 = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iterator2 = _createForOfIteratorHelper(tags),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var tag = _step2.value;
            if (tag.to.path === _this4.$route.path) {
              // this.$refs.scrollPane.moveToTarget(tag)
              // when query is different then update
              if (tag.to.fullPath !== _this4.$route.fullPath) {
                _this4.$store.dispatch('tagsView/updateVisitedView', _this4.$route);
              }
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {
      var _this5 = this;
      this.$store.dispatch('tagsView/delCachedView', view).then(function () {
        var fullPath = view.fullPath;
        _this5.$nextTick(function () {
          _this5.$router.replace({
            path: '/redirect' + fullPath
          });
        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this6 = this;
      var routerPath = view.fullPath ? view.fullPath : view;
      var index = this.visitedViews.findIndex(function (item) {
        return item.fullPath === routerPath;
      });
      if (index > -1) {
        var _path = this.visitedViews[index];
        this.$store.dispatch('tagsView/delView', _path).then(function (_ref) {
          var visitedViews = _ref.visitedViews;
          if (_this6.editableTabsValue === _path.fullPath) {
            _this6.toLastView(visitedViews, _path);
          }
        });
      }
    },
    closeOthersTags: function closeOthersTags() {
      var _this7 = this;
      this.$router.push(this.selectedTag.path).catch(function (e) {
        return e;
      });
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(function () {
        _this7.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {
      var _this8 = this;
      this.$store.dispatch('tagsView/delAllViews').then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;
        if (_this8.affixTags.some(function (tag) {
          return tag.path === view.path;
        })) {
          return;
        }
        _this8.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath).catch(function (err) {
          return err;
        });
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          this.$router.push('/');
        }
      }
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      var offsetWidth = this.$el.offsetWidth; // container width
      var maxLeft = offsetWidth - menuMinWidth; // left boundary
      var left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};

// eslint-disable-next-line no-extend-native
String.prototype.colorRgb = function () {
  var sColor = this.toLowerCase();
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    var sColorChange = [];
    for (var _i = 1; _i < 7; _i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(_i, _i + 2)));
    }
    return 'rgba(' + sColorChange.join(',') + ',0.2)';
  } else {
    return sColor;
  }
};