module.exports = {
  sys_app_name: '这里玩直播管理平台',
  // 后台名称
  sys_app_logo: 'https://new-baobao-static.oss-cn-hangzhou.aliyuncs.com/static/default_avatar.png',
  // logo
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: true,
  /**
   * 是否显示顶部导航
   */
  topNav: false,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
  themeStyle: 'dark'
};