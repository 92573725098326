import _objectSpread from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.iterator.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { constantRoutes } from '@/router';
export default {
  data: function data() {
    return {
      // 顶部栏初始数
      visibleNumber: 5,
      // 是否为首次加载
      isFrist: false
    };
  },
  computed: {
    // 顶部显示菜单
    topMenus: function topMenus() {
      return this.routers.map(function (menu) {
        return _objectSpread(_objectSpread({}, menu), {}, {
          children: undefined
        });
      });
    },
    // 所有的路由信息
    routers: function routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus: function childrenMenus() {
      var childrenMenus = [];
      this.routers.map(function (router) {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu: function activeMenu() {
      var path = this.$route.path;
      var activePath = this.routers[0].path;
      if (path.lastIndexOf('/') > 0) {
        var tmpPath = path.substring(1, path.length);
        activePath = '/' + tmpPath.substring(0, tmpPath.indexOf('/'));
      } else if (path === '/index' || path === '') {
        if (!this.isFrist) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isFrist = true;
        } else {
          activePath = 'index';
        }
      }
      this.activeRoutes(activePath);
      return activePath;
    }
  },
  mounted: function mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber: function setVisibleNumber() {
      var width = document.body.getBoundingClientRect().width - 200;
      var elWidth = this.$el.getBoundingClientRect().width;
      var menuItemNodes = this.$el.children;
      var menuWidth = Array.from(menuItemNodes).map(function (i) {
        return i.getBoundingClientRect().width;
      });
      this.visibleNumber = (parseInt(width - elWidth) / parseInt(menuWidth)).toFixed(0);
    },
    // 菜单选择事件
    handleSelect: function handleSelect(key, keyPath) {
      if (key.indexOf('http://') !== -1 || key.indexOf('https://') !== -1) {
        // http(s):// 路径新窗口打开
        window.open(key, '_blank');
      } else {
        this.activeRoutes(key);
      }
    },
    // 当前激活的路由
    activeRoutes: function activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map(function (item) {
          if (key === item.parentPath || key === 'index' && item.path === '') {
            routes.push(item);
          }
        });
      }
      this.$store.commit('permission/SET_SIDEBAR_ROUTERS', routes);
    }
  }
};