import _typeof from "/var/lib/jenkins/workspace/new-baobao-manage-web/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.iterator.map.js";
/** *
 * 将时间戳转成年月日时分秒的格式
 * timestamp：毫秒级时间戳，默认为当前时间戳
 * format：转换的格式，默认为 YYYY-MM-DD 可配：YYYY-MM-DD HH:mm:ss
 */
export function formatTimestamp() {
  var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Math.floor(Date.now());
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
  var date = new Date(Number(timestamp));
  var year = date.getFullYear();
  var month = ('0' + (date.getMonth() + 1)).slice(-2);
  var day = ('0' + date.getDate()).slice(-2);
  var hour = ('0' + date.getHours()).slice(-2);
  var minute = ('0' + date.getMinutes()).slice(-2);
  var second = ('0' + date.getSeconds()).slice(-2);
  format = format.replace('YYYY', year);
  format = format.replace('MM', month);
  format = format.replace('DD', day);
  format = format.replace('HH', hour);
  format = format.replace('mm', minute);
  format = format.replace('ss', second);
  return format;
}
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  if (time.indexOf('01-01-01') > -1) {
    return '-';
  }
  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
// 导出文件
export function exportFile() {}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params;
  search.startDate = '';
  search.endDate = '';
  if (dateRange != null && dateRange !== '') {
    search.startDate = this.dateRange[0];
    var endTime = new Date(this.dateRange[1]);
    // search.endTime = new Date(this.dateRange[1])
    endTime.setHours(31);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    search.endDate = endTime.toISOString().slice(0, 19).replace('T', ' ');
  }
  return search;
}
export function addDateRange2(params, dateRange) {
  var search = params;
  search.start_date = '';
  search.end_date = '';
  if (dateRange != null && dateRange !== '') {
    search.start_date = this.dateRange[0];
    var endTime = new Date(this.dateRange[1]);
    // search.endTime = new Date(this.dateRange[1])
    endTime.setHours(31);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    search.end_date = endTime.toISOString();
  }
  return search;
}
// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].value === '' + value) {
      actions.push(datas[key].label);
      return false;
    }
  });
  return actions.join('');
}
export function selectItemsLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].key === '' + value) {
      actions.push(datas[key].value);
      return false;
    }
  });
  return actions.join('');
}

// 字符串格式化(%s )
// export function sprintf(str) {
//   var args = arguments; var flag = true; var i = 1
//   str = str.replace(/%s/g, function () {
//     var arg = args[i++]
//     if (typeof arg === 'undefined') {
//       flag = false
//       return ''
//     }
//     return arg
//   })
//   return flag ? str : ''
// }

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return '';
  }
  return str;
}